import React from 'react';
import { somethingWentWrong } from '../../assets/images';
import EmptyPage from '../EmptyPage';
import { HOME } from '../../routes/private';

const SomethingWentWrongPage = () => (
  <div className='flex justify-center items-center m-16'>
    <EmptyPage
      height={['80vh', '100%']}
      image={{src: somethingWentWrong, width: '30%'}}
      header={{text: 'Somethings went wrong!'}}
      body={{text: 'It\'s broken, but it\'s not your fault.'}}
      clickToAction={{text: 'Take me to home page', to: HOME}}
    />
  </div>
)

export default SomethingWentWrongPage;