import { cancelIconRedMinus, inProgressWhite, orderPlacedDone, outOfStockwhite, trinagleYellowExclamation, whiteTimerIcon } from "../assets/icons"
import bangalore from "../assets/icons/pharmacy-popular-cities/bangalore.svg"
import hyderabad from "../assets/icons/pharmacy-popular-cities/hyderabad.svg"
import gurugram from "../assets/icons/pharmacy-popular-cities/gurugram.svg"
import mumbai from "../assets/icons/pharmacy-popular-cities/mumbai.svg"
import newDelhi from "../assets/icons/pharmacy-popular-cities/new-delhi.svg"
import pune from "../assets/icons/pharmacy-popular-cities/pune.svg"
import thiruvananthapuram from "../assets/icons/pharmacy-popular-cities/thiruvananthapuram.svg"
import chennai from "../assets/icons/pharmacy-popular-cities/chennai.svg"
import delhi from "../assets/icons/pharmacy-popular-cities/delhi.svg"
import ghaziabad from "../assets/icons/pharmacy-popular-cities/ghaziabad.svg"

export const RECENT_SEARCHES = 'RECENT_SEARCHES'
export const ADD_TO_CART = 'ADD_TO_CART'
export const ITEMS_ADD_REMOVE = 'ITEMS_ADD_REMOVE'
export const SET_CURRENT_ADDRESS = 'SET_CURRENT_ADDRESS'
export const SET_CATEGORY_DATA = 'SET_CATEGORY_DATA'
export const TOGGLE_ADDRESS_MODAL = 'TOGGLE_ADDRESS_MODAL'
export const UPDATE_MOBILE_NUMBER = 'UPDATE_MOBILE_NUMBER'
export const SET_PRESCRIPTION_DATA = 'SET_PRESCRIPTION_DATA'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const CLEAN_UP_CART = 'CLEAN_UP_CART'
export const TOGGLE_REMINDER = 'TOGGLE_REMINDER'
export const ADD_PRESCRIPTIONS = 'ADD_PRESCRIPTIONS'
export const PAST_PRESCRIPTIONS = 'PAST_PRESCRIPTIONS'
export const SHOW_ALERT_MODAL = 'SHOW_ALERT_MODAL'
export const UPDATE_GLOBAL_CART = 'UPDATE_GLOBAL_CART'
export const UPDATE_CART_LOADING = 'UPDATE_CART_LOADING'
export const SHOW_PRODUCT_UPDATE_STATE = 'SHOW_PRODUCT_UPDATE_STATE'
export const ADD_SUBSCRIPTION_TO_CART = 'ADD_SUBSCRIPTION_TO_CART'
export const REMOVE_SUBSCRIPTION_FROM_CART = 'REMOVE_SUBSCRIPTION_FROM_CART'
export const SHOW_VALUE_PLUS_CART_MODAL = 'SHOW_VALUE_PLUS_CART_MODAL'
export const UPDATE_CITY_DATA = 'UPDATE_CITY_DATA'
export const UPDATE_SUGGESTED_COUPON = 'UPDATE_SUGGESTED_COUPON'

export const PHARMACY_STEPS = [
  {
    text: 'You can change the location/city to order medicines.',
    id:'locationBox'
  },
  {
    text: 'You can now look for any medicines or product by just typing the name in the search bar',
    id: 'searchBox'
  }
  
]

export const icons = {
  'received': orderPlacedDone,
  'created': orderPlacedDone,
  'processed': orderPlacedDone,
  'delivered': orderPlacedDone,
  'verification_pending': inProgressWhite,
  'in_progress': inProgressWhite,
  'out_for_delivery': inProgressWhite,
  'out_of_stock': outOfStockwhite,
  'packing': inProgressWhite,
  'consultation_required': trinagleYellowExclamation,
  'cancelled': cancelIconRedMinus,
  'failed': cancelIconRedMinus,
  'onhold': whiteTimerIcon,
  'under_ops_verification': whiteTimerIcon,
  'ops_rejected': cancelIconRedMinus,
  'packed': orderPlacedDone,
  'default': orderPlacedDone,
}

export const OPD_PRESCRIPTION_INFO = [
  'The prescription and cart items will undergo verification after the order is placed.',
  'In case of a discrepancy or absence of prescription, the entire order might be canceled.',
  'Verification process can take up to 24 hrs.'
]

export const popularCities =[
  {
    name: "Bangalore",
    isSelected: false,
    icon: bangalore,
    zipCode: '560001'
  },
  {
    name: "Hyderabad",
    isSelected: false,
    icon: hyderabad,
    zipCode: '500029'
  },
  {
    name: "Gurugram",
    isSelected: false,
    icon: gurugram,
    zipCode: '122001'
  },
  {
    name: "Mumbai",
    isSelected: false,
    icon: mumbai,
    zipCode: '400001'
  },
  {
    name: "New Delhi",
    isSelected: false,
    icon: newDelhi,
    zipCode: '122051'
  },
  {
    name: "Pune",
    isSelected: false,
    icon: pune,
    zipCode: '411011'
  },
  {
    name: "Thiruvananthapuram",
    isSelected: false,
    icon: thiruvananthapuram,
    zipCode: '695004'
  },
  {
    name: "Chennai",
    isSelected: false,
    icon: chennai,
    zipCode: '600083'
  },
  {
    name: "Delhi",
    isSelected: false,
    icon: delhi,
    zipCode: '110008'
  },
  {
    name: "Ghaziabad",
    isSelected: false,
    icon: ghaziabad,
    zipCode: '201002'
  },
]