import React from 'react';
import { CardTitle, CardText} from './typography';
import Icon from './Icon';
import themes from '../../themes';

const FormAlert = ({icon={}, type, title, subtitle, fontSize='text-sm'}) => {
  return (
    <div className={`box-border ${fontSize} p-3 my-4`} style={{backgroundColor: themes.alerts[type]}}>
      <div className='flex'>
        {icon.src && <Icon src={icon.src} size={icon.size} />}
        {title && <CardTitle ml='5px' mb='5px' pb='0px'>{title}</CardTitle>}
      </div>
      {subtitle && <CardText>{subtitle}</CardText>}
    </div>
  )
}
  
export default FormAlert;