import React from 'react'
import { Icon, ToggleVisibility } from '../../atoms';
import ekincarePoint from '../../../assets/images/benefits/ekincare-point.svg';
// import Text from '../../atoms/typography/Text';
import notificationNew from '../../../assets/icons/notificationNew.svg';
import notificationAlertNew from '../../../assets/icons/notificationAlertNew.svg';
import { useHistory } from 'react-router-dom';
import { MOBILE_ACCOUNT, NOTIFICATIONS, REWARDS } from '../../../routes/private';
import useFetchNotifications from '../../../utils/customHooks/useFetchNotifications';
import useIsMobileView from '../../../utils/customHooks/useIsMobileView';
import useUserDetails from '../../../utils/customHooks/useUserDetails';
import { formatNumberWithCommas, getNameInitialization } from '../../../utils/common';
import tracker from '../../../utils/tracker';
import { ISOS, ekincareLogo, targetName } from '../../../utils/constants';

const NameBanner = () => {
  const { push } = useHistory();
  const { rewards, services, partner_logo, company_logo, user, expiringRewardPoints } = useUserDetails();
  const logo_src = partner_logo || company_logo || ekincareLogo;
  const fullName = user?.name || user?.first_name;
  const { checkUnreadNotifications } = useFetchNotifications();
  const isMobileView = useIsMobileView();
  const notification_scr = checkUnreadNotifications() ? notificationAlertNew : notificationNew;
  const rewardText = rewards.rewardsPoints > 0 ? formatNumberWithCommas(rewards.rewardsPoints) : 'Earn H-Points';

  const notificationClickHandler = () => (
    push(NOTIFICATIONS),
    tracker.track('hs_notifications')
  )

  const profileClickHandler = () => (
    push(MOBILE_ACCOUNT),
    tracker.track('hs_user_profile')
  )

  const onClickRewards = () => {
    push(REWARDS)
    tracker.track('hs_hpoints', {hpoints: parseInt(rewards.rewardsPoints) || 0})
  }

  return (
    <div className='flex justify-between items-center'>
      <ToggleVisibility visibleOn={{mobile: true, desktop: false, tablet: false}}>
        {partner_logo !== '' && <Icon height='auto' width='auto' maxHeight={'45px'} maxWidth={'107px'} src={logo_src}/>}
      </ToggleVisibility>
      <div className='flex items-center'>
        {( targetName !== ISOS && !user.has_remote_wallet && services?.enable_rewards !== 0 ) && 
          <div id={expiringRewardPoints > 0 ? "reward-box-container" : ""} onClick={onClickRewards} className={`flex justify-between items-center border rounded-lg cursor-pointer transition-transform duration-500 transform hover:scale-110 mr-2 py-1.5 px-2.5 sm:mr-4`}>
            <Icon size={'lg'} pb={1} mr={3} src={ekincarePoint} alt="Exp Points"/>
            {expiringRewardPoints > 0 && <div className='overflow-hidden relative h-6 w-fit'>
              <div className='vertical-rotator flex flex-col'>
                <div className='flex flex-col h-9'>
                  <p className='text-sm font-pn-extrabold leading-3' style={{color: "#e74c3c"}}>{formatNumberWithCommas(expiringRewardPoints)}</p>
                  <div className='text-xs font-pn-semibold leading-3'>expiring</div>
                </div>
                <div className='flex items-center h-6'>
                  <p className='text-sm font-pn-extrabold text-gray-600 leading-3'>{rewardText}</p>
                </div>
              </div>
            </div>}
            {expiringRewardPoints == 0 && 
              <>
                <p className='text-sm font-pn-extrabold text-gray-600' style={{lineHeight: "12px"}}>{rewardText}</p>
              </>
            } 
          </div>
        }
        <img className={`cursor-pointer h-10 w-10 rounded-lg p-2 hover:bg-blue-100 ${isMobileView ? '' : 'mr-4'}`} onClick={notificationClickHandler} src={notification_scr}/>
      </div>
      <ToggleVisibility visibleOn={{mobile: false, desktop: true, tablet: true}}>
        <p className='cursor-pointer flex justify-center items-center w-10 h-10 rounded-full tracking-wider font-pn-semibold text-base bg-green-200 text-gray-800' onClick={profileClickHandler}>{getNameInitialization(fullName)}</p>
      </ToggleVisibility>
    </div>
  )
}
export default NameBanner;