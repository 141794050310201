import React from 'react';
import Link from './Link';
import Icon from './Icon';
import tracker from '../../utils/tracker';

const LinkWithIcon = ({src, iconSize='sm', href="#", target, linkSize='xs', linkText, eventName, eventData}) => {
  const onClick = () => {
    tracker.track(eventName, eventData)
  }
  return(
    <div className='flex'>
      <Icon src={src} size={iconSize} />
      <Link size={linkSize} variant='primary' target={target} onClick={onClick} href={href} bold>{linkText}</Link>
    </div>
  )
}

export default LinkWithIcon