import React from 'react'
import { Icon } from '../atoms'
import { Text } from '../atoms/typography'
import { IconWithText } from '.'
import chatWithUs from '../../assets/icons/HealthCheckDrawer/chat-with-us.svg'
import ReviewDetailPageLoader from '../organisms/loaders/ReviewDetailPageLoader'

const HelpFooter = ({ src=chatWithUs, mins='1 min', text='Chat with us', footerProps, backgroundImage, loading, helpCardClickHandler = () => {}, maxWidth='max-w-sm' }) => {

  return (
    <div onClick={helpCardClickHandler} className={`bg-gray-200 shadow-lg rounded-lg border-0 p-4 cursor-pointer w-full ${maxWidth} bg-rigth bg-no-repeat bg-cover`} style={{backgroundImage: `url(${backgroundImage})`}}>
      {loading ? 
        <ReviewDetailPageLoader SpinnerRequired={false} text={'Connecting...'} position={'relative'} backgroundOpacity={'opacity-100'} backgroundColor='bg-gray-200' height={'h-20'} textFontWeight='bolder' textFontSize='lg'/> 
        :
        <div className='flex flex-col'>
          <div className='flex justify-between'>
            <div className='flex flex-col'>
              <Text fontSize='mdL' color='extraInformation' bold>{text}</Text>
              <Text fontSize='sm' color='subtitle' >{`typically replies in ${mins}`}</Text>
            </div>
            <div className='block w-1/3 justify-center'>
              <Icon src={src} size='20px' height='40px'/>
            </div>
          </div>
          {footerProps && 
            <IconWithText src={footerProps.src} flexDirection='row' mr='6px' marginBottom='-18px' 
              marginTop='16px' mx='-16px' px='16px' pt='10px' pb='8px' bgColor='mediumDarkGrey' borderRadius='0 0 10px 10px'>
              <span className='text-gray-700 font-pn-semibold text-sm sm:text-base'>{footerProps.text}</span>
            </IconWithText>}
        </div>}
    </div>
  )
}

export default HelpFooter;
