import React, { lazy } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { withLazyLoadingHOC, withErrorBoundaryHOC } from '../utils/hocs';
import MobileWebPwaBanner from '../components/molecules/MobileWebPwaBanner';
import { isMobileWebPwa } from '../utils/common';
const InsuranceTermsAndConditions = lazy(() => import('../pages/InsuranceTermsAndConditions'))
const SamlLoginPage = lazy(() => import('../pages/SamlLoginPage'))
const PwaSSOLoginPage = lazy(() => import('../pages/PwaSSOLoginPage'))
const AesLoginPage = lazy(() => import('../pages/AesLoginPage'))
const SignInPage = lazy(() => import('../pages/SignInPage'))
const RegistrationPage = lazy(() => import('../pages/RegistrationPage'))
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions'))
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'))
const MediaAssist = lazy(() => import('../pages/MediaAssistLoginPage'))
const TermsAndConditionIsos =lazy(()=>import('../pages/TermsAndConditionIsos'))

export const LOGIN = '/login';
export const REGISTRATION = '/registration';
export const TERMS_CONDITIONS = '/terms-and-conditions';
export const PRIVACY_POLICY = '/privacy-policy';
export const SAML_LOGIN = '/saml-login'
export const PWA_SSO = '/pwa-login'
export const AES_LOGIN = '/aes-login'
export const ROOT = '/'
export const INSURANCE_LOGIN = '/icicilombard/login'
export const INSURANCE_REGISTRATION = '/icicilombard/registration'
export const BHARATI_AXA = '/icicilombard'
export const INSURANCE_TERMS_CONDITIONS = '/icicilombard-terms-and-conditions'
export const MEDIA_ASSIST_LOGIN = '/mediassist-login';
export const TERMS_CONDITIONS_ISOS ='/isos-terms'

export const PUBLIC_ROUTES = [
  { path: LOGIN, component: SignInPage, exact: true},
  { path: REGISTRATION, component: RegistrationPage, exact: true},
  { path: BHARATI_AXA, component: RegistrationPage, exact: true},
  { path: INSURANCE_LOGIN, component: SignInPage, exact: true},
  { path: INSURANCE_REGISTRATION, component: RegistrationPage, exact: true},
  { path: INSURANCE_REGISTRATION, component: RegistrationPage, exact: true},
  { path: TERMS_CONDITIONS, component: TermsAndConditions, exact: true},
  { path: INSURANCE_TERMS_CONDITIONS, component: InsuranceTermsAndConditions, exact: true},
  { path: PRIVACY_POLICY, component: PrivacyPolicy, exact: true},
  { path: SAML_LOGIN, component: SamlLoginPage, exact: true},
  { path: PWA_SSO, component: PwaSSOLoginPage, exact: true},
  { path: AES_LOGIN, component: AesLoginPage, exact: true},
  { path: MEDIA_ASSIST_LOGIN, component: MediaAssist, exact: true},
  { path: TERMS_CONDITIONS_ISOS, component:TermsAndConditionIsos,exact:true}
].map((route) => {
  return ({...route, component: withRouter(withErrorBoundaryHOC(withLazyLoadingHOC(route.component)))})
}) // withRouter helps us in accessing history.

const publicRoute = ({ component: Component, path, ...rest }, index, showMobileWebPwaBanner) => {
  return (
    <Route path={Array.isArray(path) ? path.reduce((acc, p) => acc.concat(p), []) : path} {...rest} key={index} exact render={(props) => (
      <>
        {(isMobileWebPwa() && showMobileWebPwaBanner) && <MobileWebPwaBanner />}
        <Component {...props} />
      </>
    )}/>
  )
}

const publicRoutes = (showMobileWebPwaBanner) => (
  PUBLIC_ROUTES.map((route, index) => {
    return publicRoute({...route}, index, showMobileWebPwaBanner);
  })
)

export default publicRoutes;