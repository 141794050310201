import React from 'react';
import { notFound } from '../../assets/images';
import EmptyPage from '../EmptyPage';
import { HOME } from '../../routes/private';

const NotFoundPage = () => ( 
  <div className='flex justify-center items-center sm:mt-16'>
    <EmptyPage
      image={{src: notFound, width: '30%'}}
      header={{text: 'Page not found!'}}
      body={{text: 'Page you are looking for does not found'}}
      clickToAction={{text: 'Take me to home page', to: HOME}}
    />
  </div>
)

export default NotFoundPage;