/* private api endpoints starts here */

export const API_ARTICLES = '/v2/customers/rss_feeds';
export const API_ARTICLE_DETAILS_GET = (id) => `/v2/customers/rss_feeds/${id}`
export const API_USER_LOGIN = '/v1/customers/login'
export const API_USER_LOGOUT = '/v1/customers/logout'
export const WEBVIEW_API_USER_LOGOUT = '/v3/customers/logout'
export const API_SAML_AUTHENTICATE = '/v1/customers/sso-create'
export const API_AES_AUTHENTICATE = '/v1/customers/aes-login'
export const API_CUSTOMER_WIZARD = '/v2/customers/wizard';
export const API_CUSTOMERS_WELLNESS_REPORT = `/v2/customers/wellness-report`;
export const API_WELLNESS_TRENDS = (filter) => `/v3/customers/trends/wellness?filter=${filter}`;
export const API_BMI_SCORE_TRENDS = (filter) => `/v3/customers/trends/bmi?filter=${filter}`;
export const API_CUSTOMERS_UPDATE = `/v1/customers/update`;
export const API_UPDATE_MOBILE_NIUMBER = `/v1/customers/update_mobile_number`;
export const API_VERIFY_OTP = `/v1/customers/verify_otp`;
export const API_OVERRIDE_MOBILE_NUMBER = (number) => `/v1/customers/send_mobile_number_override_otp?new_mobile_number=${number}`;
export const MEDIA_ASSIST_AUTH = `/v1/customers/mediassist-sso`
export const PWA_SSO_LOGIN = (slug) => `/v1/customers/pwa-sso/${slug}`;

export const API_CUSTOMER_WEARABLES = `/v2/customers/wearables`;
export const API_CUSTOMER_WEARABLE_CREATE_TOKEN = `/v2/customers/wearables/create_token`;
export const API_CUSTOMERS_WEARABLES_SYNCHRONIZING = `/v2/customers/wearables/synchronizing`;
export const API_CUSTOMERS_WEARABLES_WHEN_TO_SYNC = `/v2/customers/wearables/when_to_sync`;
export const API_CUSTOMERS_WEARABLES_GARMIN_URL = `/v2/customers/wearables/garmin_url`;
export const API_SYNC_STEPS = `/v2/customers/steps`;
// Doctor chat related apis

export const API_CHAT_INIT = '/v1/customers/consultation_requests/init'
export const API_FETCH_MESSAGES = (consultationRequestId) => `/v1/customers/consultation_requests/${consultationRequestId}/messages`
export const API_CANCEL_REQUEST = (consultationRequestId) => `/v1/customers/consultation_requests/${consultationRequestId}/cancel`
export const API_FETCH_PAYMENT_DETAILS = (consultationRequestId) => `/v1/customers/consultation_requests/${consultationRequestId}/payment_details`
export const API_PROCESS_PAYMENT = (consultationRequestId) => `/v1/customers/consultation_requests/${consultationRequestId}/process_payment`
export const API_CREATE_REQUEST = '/v1/customers/consultation_requests'
export const API_SUBMIT_FEEDBACK = `/v1/customers/consultations/feedback`
export const API_AUTO_REJECTION_API = (consultationRequestId) => `/v2/customers/family_doctor/consultation_requests/${consultationRequestId}/auto_reject`

// Notification related api:
export const API_NOTIFICATIONS = '/v2/customers/notifications';
export const API_READ_ALL_NOTIFICATIONS = '/v2/customers/notifications/read_all';
export const API_NOTIFICATIONS_VIEW_ALL = '/v2/customers/notifications/view_all';


//Challenges related apis
export const API_FETCH_CHALLENGES = '/v1/customers/challenges'
export const API_START_CHALLENGE = (challenge_id) => `/v1/customers/challenge/${challenge_id}/start`
export const API_VIEW_CHALLENGE = (challenge_id) => `/v1/customers/challenge/${challenge_id}`
export const API_VIEW_CUSTOMER_CHALLENGE = (customer_challenge_id) => `/v1/customers/challenge/${customer_challenge_id}`
export const API_INDIVIDUAL_LEADER_BOARD = '/v2/customers/leaderboard'
export const API_TEAM_LEADER_BOARD = '/v3/customers/teams/leaderboard'
export const API_VIEW_MEMBER_PROFILE = (member_id) => `/v2/customers/profile/${member_id}`
export const API_CHECKIN = (customer_challenge_id) => `/v1/customers/challenge/${customer_challenge_id}/checkin`
export const API_MULTIPLE_CHECKIN = `/v2/customers/challenge/multiple_checkins`
export const API_STOP_CHALLENGE = (customer_challenge_id) => `/v1/customers/challenge/${customer_challenge_id}/stop`
export const API_RESTART_CHALLENGE = (customer_challenge_id) => `/v1/customers/challenge/${customer_challenge_id}/restart`
export const API_RETAKE_CHALLENGE = (customer_challenge_id) => `/v1/customers/challenge/${customer_challenge_id}/retake`
export const API_VIEW_TEAM_PROFILE = (team_id, during) => `/v3/customers/teams/${team_id}?filter=${during}`
export const API_JOIN_TEAM = '/v3/customers/teams/join'
export const DAY_WISE_STEPS_SUMMARY = (id, during) => `/v3/customers/day_wise_steps_summary?customer_id=${id}&during=${during}`
export const DAY_WISE_CALORIES_SUMMARY = (id, during) => `/v3/customers/day_wise_calories_summary?customer_id=${id}&during=${during}`

export const API_VIEW_BADGES_LIST = `/v2/customers/teams/badges`
export const API_VIEW_ALL_TEAMS = (page = 1) => `/v3/customers/teams?page=${page}`
export const API_SEARCH_TEAMS = (term) => `/v3/customers/teams/search?term=${term}`

export const API_ACCEPT_CHALLENGE_INVITATION = (id) => `/v2/customers/challenge-invitations/${id}/accept`
export const API_REJECT_CHALLENGE_INVITATION = (id) => `/v2/customers/challenge-invitations/${id}/reject`

export const API_ACCEPT_TEAM_INVITATION = (id) => `/v2/customers/teams/${id}/accept`
export const API_REJECT_TEAM_INVITATION = (id) => `/v2/customers/teams/${id}/reject`

export const API_SEARCH_TEAM_MEMBERS = `/v2/customers/search_customers`
export const API_CHALLENGE_INVITATIONS = (id) => `/v2/customers/challenges/${id}/invitations`
export const API_SEND_CHALLENGE_INVITATION = (id) => `/v2/customers/challenges/${id}/invite`
export const API_ABLY_AUTH_URL = '/v1/customers/token_request'
export const API_CONSULTATIONS_DOCUMENTS = '/v1/customers/consultations/documents'

//teams
export const API_FETCH_TEAMS_LOGOS = '/v3/customers/teams/logos'
export const API_CREATE_TEAMS = '/v3/customers/teams'
export const API_INVITE_PEOPLE = '/v3/customers/team_memberships/invite'
export const API_LEAVE_TEAM = '/v3/customers/teams/leave'
export const API_REJECT_TEAM = (id) => `/v3/customers/team_memberships/${id}/reject`

// Family Members
export const API_FETCH_FAMILY_MEMBERS = (planId) => `/v2/customers/family_members?plan_id=${planId ?? ''}`
export const API_ADD_FAMILY_MEMBER = '/v2/customers/add_new_family_member'
export const API_SHARE_LOGIN_INSTRUCTIONS = '/v2/customers/send_login_instructions'

export const API_FETCH_MEMBER_INFO = (id) => `/v2/customers/family_members/${id}`
export const API_VERIFY_EMAIL = '/v1/customers/update_personal_email'
export const API_VERIFY_EMAIL_OTP = '/v1/customers/verify_personal_email'
export const API_UPDATE_FAMILY_MEMBER_RELATION = (dependent_id) => `/v2/customers/family_members/${dependent_id}/update_relation
`
//

export const API_FETCH_APPOINTMENT_SLOTS = (id) => `/v2/customers/appointments/${id}/slots`
export const API_RESCHEDULE_APPOINTMENT = (id) => `/v3/customers/benefits/appointments/${id}/reschedule`
export const API_VOUCHER_DOWNLOAD = (id, sendFileUrl = true) => {
  const queryParams = sendFileUrl ? "?send_file_url=true" : "";
  return `/v2/customers/appointments/${id}/voucher_download${queryParams}`
}

//Annual Health Checkups
export const API_FETCH_SPONSOR_PACKAGE_DETAILS = '/v2/customers/health_checks/packages'
// export const API_FETCH_PROVIDERS = (type) => `/v3/customers/benefits/${type}/providers`
export const API_FETCH_PROVIDERS = (type, planId, page, per_page, doctorName) => {
  let url = `/v3/customers/benefits/${type}/providers?plan_id=${planId ?? ''}${page && per_page ? `&page=${page}&per_page=${per_page}` : ''}`
  if (doctorName) url += `&doctor_name=${doctorName}`;
  return url;
};
export const API_FETCH_SLOTS = (type) => `/v3/customers/benefits/${type}/slots`
export const API_CREATE_APPOINTMENT_REQUEST = '/v3/customers/benefits/appointments'
export const API_DEPENDENTS_PACKAGES = (benefit_type, customer_id, collection_type, planId) => `v3/customers/benefits/${benefit_type}/dependent_packages?customer_id=${customer_id}&collection_type=${collection_type}&plan_id=${planId ?? ''}`

//Normal Health Checks
export const API_FETCH_LABTEST_MEMBERS = (id) => `/v3/customers/benefits/lab_test/packages/${id}/members`;
export const API_FETCH_POPULAR_LAB_TESTS = '/v3/customers/benefits/lab_test/packages/popular_lab_tests'
export const API_FETCH_POPULAR_FUNCTION_TESTS = '/v3/customers/benefits/lab_test/packages/popular_function_tests'
export const API_FETCH_LAB_TEST_PACKAGES = '/v3/customers/benefits/lab_test/packages'
export const API_FETCH_PREVIOUS_BOOKED_PACKAGE = '/v3/customers/benefits/lab_test/packages/previously_booked_packages'
export const API_FETCH_POPULAR_PACKAGES = '/v3/customers/benefits/lab_test/packages/popular_packages'
export const API_FETCH_LAB_TESTS_INFO = '/v3/customers/benefits/lab_test/info'
export const API_FETCH_SEARCHED_PACKAGES = '/v2/customers/normal_health_checks/packages/search'
export const API_FETCH_ELIGIBLE_MEMBERS_FOR_SEARCHED_PACKAGE = (id) => `/v2/customers/packages/${id}/family_members`
export const API_UPLOAD_EWAP_APPIONTMENTS = '/v2/customers/appointments'
export const API_UPLOAD_RESCHEDULE_EWAP_APPIONTMENTS = id => `/v3/customers/benefits/appointments/${id}/reschedule`

//Mental Wellness Appointment apis
export const API_EWAP_DATE_SLOTS = (mode, providerId) => `/v2/customers/appointments/mental_wellness/service_dates?service_mode=${mode}${providerId ? `&provider_id=${providerId}` : ``}`;
export const API_EWAP_TIME_SLOTS = (date, mode, providerId) => `/v2/customers/appointments/mental_wellness/slots?date=${date}&service_mode=${mode}${providerId ? `&provider_id=${providerId}` : ``}`
export const EWAP_APPOINTMENT_CANCEL_REQUEST = id => `/v3/customers/benefits/appointments/${id}/cancel`
export const API_EWAP_RESCHEDULE_DATE_SLOTS = (providerId, mode) => `/v2/customers/appointments/mental_wellness/service_dates?provider_id=${providerId}&service_mode=${mode}`
export const API_EWAP_RESCHEDULE_TIME_SLOTS = (date, providerId, mode, appointmentId) => `/v2/customers/appointments/mental_wellness/slots?date=${date}&provider_id=${providerId}&service_mode=${mode}&appointment_id=${appointmentId}`

//Feedback
export const API_APPOINTMENT_FEEDBACK_SUBMIT = (id) => `/v2/customers/appointments/${id}/appointment_feedback`
//

//Documents
export const API_DOCUMENTS_GET = `/v2/customers/documents`
export const API_DOCUMENTS_DELETE = (documentId) => `/v2/customers/documents/${documentId}/delete_documents`
export const API_DOCUMENTS_RENAME = (documentId) => `/v2/customers/documents/${documentId}/rename_document`
export const API_DOCUMENTS_CREATE = `/v2/customers/documents`

//Pharmacy Orders
export const API_UPLOAD_PRESCRIPTIONS = '/v2/customers/prescriptions/upload'
export const API_FETCH_ADDRESSES = '/v2/customers/addresses'
export const API_CREARE_ADDRESS = '/v2/customers/addresses'
export const API_UPDATE_ADDRESS = (id) => `/v2/customers/addresses/${id}`
export const API_PLACE_PHARMACY_ORDER = '/v2/customers/epharmacy_orders'
export const API_DELETE_ADDRESS = (id) => `/v2/customers/addresses/${id}`
//

//Medical History

export const API_TIMELINE_GET = `/v2/customers/medical_histories`
export const API_BODY_ASSESSMENT_GET = (assessment_id) => `/v1/customers/body_assessments/${assessment_id}`
export const API_DENTAL_ASSESSMENT_GET = (assessmentId) => `/v2/customers/dental_assessments/${assessmentId}`
export const API_VISION_ASSESSMENT_GET = (assessment_id) => `/v2/customers/vision_assessments/${assessment_id}`
export const API_ASSESSMENT_DOCUMENTS_GET = (assessmentId) => `/v2/customers/health_assessments/${assessmentId}/medical_records`
export const API_ASSESSMENT_NOTES_GET = (assessmentId) => `/v2/customers/health_assessments/${assessmentId}/notes`
export const API_ASSESSMENT_LAB_RESULTS_GET = (assessmentId) => `/v1/customers/body_assessments/${assessmentId}`
export const API_LAB_RESULT_TRENDS = `/v1/customers/lab_results/trends`
export const API_NEW_LAB_RESULTS_TRENDS_GET = (assessmentId, resultId, filter, nested) => `/v2/customers/body_assessments/${assessmentId}/lab_results/${resultId}/trends?filter=${filter}&nested=${nested}`
export const API_FETCH_ONSITE_RESULTS = (assessmentId) => `/v2/customers/onsite_assessments/${assessmentId}`

//Consultations
export const API_CONSULTATION_GET = (consultationId) => `/v2/customers/consultations/${consultationId}`
export const API_CONSULTATION_MESSAGES_GET = (consultationId) => `/v2/customers/consultations/${consultationId}/messages`
export const API_CONSULTATION_PRESCRIPTIONS_GET = (consultationId) => `/v2/customers/consultations/${consultationId}/doctor_prescriptions`
export const API_DOWNLOAD_PRESCRIPTION = (prescriptionId, sendFileUrl = true) => {
  const queryParams = sendFileUrl ? "?send_file_url=true" : "";
  return `/v1/customers/prescriptions/${prescriptionId}/download${queryParams}`;
};
export const API_PATIENT_DETAILS = () => `/v2/customers/update-details`

//Health Coach
export const API_FETCH_HEALTH_COACH_TIMELINE = (program_enrollment_id) => `/v2/customers/program_enrollments/${program_enrollment_id}/timelines`//?week=3`
export const API_HEALTH_COACH_APPOINTMENT_REQUEST = (program_enrollment_id) => `/v2/customers/employee_appointment_requests/${program_enrollment_id}`
export const API_HEALTH_COACH_DOCTOR_DETAIL = (timeline_id) => `/v2/customers/health_coach/consultation-details?timeline_id=${timeline_id}`
export const API_FETCH_HEALTH_COACH_DOCTOR_SLOTS = (doctorId) => `/v2/customers/health_coach/doctors/${doctorId}/schedule`
export const API_HEALTH_COACH_CONSULTATION_REQUEST = `/v2/customers/health_coach/appointment_requests`
export const API_UPDATE_HEALTH_COACH_VITALS = `/v2/customers/health_coach/customer-goal-vital/create`

export const API_FETCH_HEALTH_COACH_PROGRAMS = '/v2/customers/condition_managements'
export const API_FETCH_HEALTH_COACH_PROGRAM = (id) => `/v2/customers/condition_managements/${id}`
export const API_HEALTH_COACH_REQUEST = '/v2/customers/condition_management_requests'
export const API_HEALTH_COACH_PROGRAM_ENROLLMENT = '/v3/customers/program_enrollments'
export const API_HEALTH_COACH_PROGRAM_ENROLLMENT_PAYMENT_OPTION = '/v3/customers/program_enrollments/payment-options'
export const API_HEALTH_COACH_PROGRAM_ENROLLMENT_PAYMENT_DETAILS = (id) => `/v3/customers/condition_managements/${id}/payment_details`
export const API_HEALTH_COACH_PROGRAM_ENROLLMENT_POST_PAY = '/v3/customers/program_enrollments/post-payment'
export const API_FETCH_HEALTH_COACH_FAMILY_MEMBERS = (programId) => `/v2/customers/health_coach/family_members?company_program_definition_id=${programId}`
export const API_FETCH_HEALTH_COACH_SLOTS = '/v2/customers/health_coach/slots'

// V2 version enrollment api's
export const API_PROGRAM_ENROLLMENT = '/v2/customers/program_enrollments'
export const API_PROGRAM_ENROLLMENT_PAYMENT_OPTION = '/v2/customers/program_enrollments/payment-options'
export const API_PROGRAM_ENROLLMENT_PAYMENT_DETAILS = (id) => `/v2/customers/condition_managements/${id}/payment_details`
export const API_PROGRAM_ENROLLMENT_POST_PAY = '/v2/customers/program_enrollments/post-payment'
//

//Vaccinations
export const API_IMMUNIZATIONS_GET = `/v2/customers/immunizations`
export const API_RECORD_MEMBER_VACCINATION_DETAILS = `/v2/customers/customer_covid_information/create`
export const API_AVAILABLE_VACCINES_DOSE = '/v2/customers/health_checks/available_vaccines'

// Employee Wellbeing Assistance Program (EWAP)
export const API_EWAP = '/v2/customers/ewap/encrypted-string'
export const API_EWAP_POST_PAYMENT = '/v2/customers/appointments/post_payment'

//Events

export const API_EVENTS_GET = (type) => `/v1/customers/events?event_type=${type}`
export const API_EVENT_REGISTRATIONS_POST = `/v1/customers/events/register`
export const API_EVENT_DETAILS_GET = (eventId) => `/v1/customers/events/${eventId}`
export const API_EVENT_REGISTRATION_GET = (eventId) => `/v1/customers/events/${eventId}/event_registrations`

// Registrations api routes
export const API_REGISTRATIONS_VERIFY_EMAIL = '/v2/customers/auth/check_email'
export const API_REGISTRATIONS_VERIFY_COMPANY_CODE = '/v2/customers/verify-sponsor-code'
export const API_REGISTRATIONS_REGISTER_EMPLOYEE = '/v2/customers/self-register'
export const API_REGISTRATIONS_VERIFY_OTP = '/v2/customers/auth/check_otp'
export const API_REGISTRATIONS_SEND_OTP = '/v2/customers/passwords/send_or_resend_otp'
export const API_REGISTRATION_VERIFY_MOBILE_NUMBER = '/v2/customers/verify-mobile-number'
export const API_REGISTRATION_SET_PASSWORD = '/v2/customers/passwords/verify_password'
export const API_REGISTRATION_OTP_LOGIN = '/v2/customers/passwords/verify_otp_new'
export const API_VERIFY_SELF_REGISTER_OTP = '/v2/customers/auth/verify-self-registration-otp'

//Health Profile
export const API_FETCH_HEALTH_PROFILE_DATA = `/v2/customers/health_profile_data`
//

//Trends
export const API_FETCH_TRENDS_DATA = `/v1/customers/insights`

//CSRF Token
export const CSRF_TOKEN_API = '/v1/customers/tokens/csrf'

//Customer Details
export const API_FETCH_CUSTOMER_PROFILE = `/v2/customers/show`

//Booking History
export const API_FETCH_BOOKING_HISTORY = `/v2/customers/orders`
export const API_GYMS_ORDER_SUMMARY = (orderId) => `/v2/customers/fitness/appointments/${orderId}`
export const API_GYM_SUMMARY = (orderId) => `/v1/customers/fitmein_appointments/${orderId}`
export const API_FETCH_FEEDBACK_OPTIONS = '/v2/customers/feedback/show_options'
export const API_FETCH_PHARMACY_ORDER_HISTORY_DETAILS = (order_id) => `/v2/customers/epharmacy_orders/${order_id}`
export const API_FETCH_GYM_BOOKING_HISTORY = (appointment_id) => `v1/customers/fitmein_appointments/${appointment_id}`
export const API_CANCEL_BENEFITS_APPOINTMENT = (apt_id) => `/v3/customers/benefits/appointments/${apt_id}/cancel`

//Wallet
export const WALLET_TRANSACTION_HISTORY = '/v2/customers/wallet_transactions'
export const WALLET_TRANSACTION_DETAIL = (id) => `/v2/customers/wallet_transactions/${id}`
export const API_KNOW_MORE_WALLET_BALANCE = (type, planId) => `/v3/customers/benefits/${type}/know_more?plan_id=${planId ?? ''}`
//


//Dashboard
export const API_FETCH_SERVICES = '/v2/customers/dashboard/services'
export const API_FETCH_TALK_WITH_DOC_DATA = '/v2/customers/dashboard/talk_with_doc'
export const API_NEW_DASHBOARD = (planId) => `/v3/customers/dashboard?plan_id=${planId ?? ''}`
export const API_NEW_USER_TO_PHARMACY = '/v3/customers/dashboard/checks'

//Ongoing Activities
export const API_FETCH_ONGOING_ACTIVITIES = '/v2/customers/ongoing_activities'
//

//Marketing tiles


export const API_CUSTOM_TILES_GET = `/v2/customers/announcements`


// Family Doctor
export const API_CONSULTATION = '/v2/customers/consultation';
export const API_FAMILY_DOCTOR_APPOINTMENT_REQUESTS = `/v2/customers/family_doctor/appointment_requests`;
export const API_FAMILY_DOCTOR_APPOINTMENT_REQUEST_DETAILS = (id) => `/v2/customers/family_doctor/appointment_requests/${id}`;
export const API_FAMILY_DOCTOR_SLOTS = `/v2/customers/family_doctor/doctor_slots`
export const API_FAMILY_DCOTOR_CHAT_SLOTS = '/v2/customers/family_doctor/family_doctor_chat_availability'
export const API_FAMILY_DOCTOR_CONSULTATION_REQUESTS = `/v2/customers/family_doctor/consultation_requests`
export const API_FAMILY_DOCTOR_LAST_CONSULTATION_REQUEST = `/v2/customers/family_doctor/consultation_requests/last_consultation`
export const API_FAMILY_DOCTOR_CONSULTATION_REQUESTS_MESSAGES = (id) => `/v2/customers/family_doctor/consultation_requests/${id}/messages`
export const API_FAMILY_DOCTOR_AVAILABILITY_REMINDERS = `/v2/customers/family_doctor/doctor_availability_reminders`
export const API_FAMILY_DOCTOR_AVAILABILITY_REMINDERS_FETCH = `/v2/customers/family_doctor/doctor_availability_reminders/fetch`
export const API_FAMILY_DOCTOR_CANCEL_APPOINTMENT = (id) => `/v2/customers/family_doctor/appointment_requests/${id}/cancel`
export const API_FAMILY_DOCTOR_CANCEL_CONSULTATION_REQUESTS = (id) => `/v2/customers/family_doctor/consultation_requests/${id}/cancel`
export const API_FAMILY_DOCTOR_MISSED_APPOINTMENTS = `/v2/customers/dashboard/missed_appointment`
export const API_FAMILY_DOCTOR_APPOINTMENT_REQUESTS_RESCHEDULE = (id) => `/v2/customers/family_doctor/appointment_requests/${id}/reschedule`
// Pharmacy
export const API_FETCH_ALL_PRESCRIPTIONS = `/v2/customers/prescriptions/all`
export const API_FETCH_PHARMACY_ORDER_DETAILS = (id) => `/v2/customers/epharmacy_orders/${id}/details`
export const API_MOVE_PRESCRIPTIONS = `/v2/customers/prescriptions/move`
export const API_FETCH_PHARMACY_TRACKING = (id) => `/v2/customers/epharmacy_orders/${id}/track`
export const API_FETCH_PHARMACY_SERVICEABLE_CITIES = `v2/customers/epharmacy/serviceable/cities`

// Feedback
export const API_FETCH_FEEDBACK = `/v2/customers/feedback`
export const API_FEEDBACK_SUBMIT = `/v2/customers/feedback`

//Health tab
export const API_FETCH_MEDICAL_HISTORY = `/v2/customers/medical_reports`
export const API_DELETE_ASSESSMENT = (id) => `/v2/customers/health_assessments/${id}`
export const API_DOWNLOAD_DOCUMENT = (id, sendFileUrl = true) => {
  const queryParams = sendFileUrl ? "?send_file_url=true" : "";
  return `/v1/customers/documents/${id}/download${queryParams}`;
};
export const API_FETCH_CONSULTATIONS_HISTORY = `/v2/customers/consultations`
export const API_DOCTOR_DOCUMENTS = `/v2/customers/doctor_documents`

//Account
// TODO : Need to check if this is used  
export const API_FETCH_INVOICES = `/v2/customers/medical_invoices`
export const API_DOWNLOAD_INVOICE = (id, sendFileUrl = true) => {
  const queryParams = sendFileUrl ? "?send_file_url=true" : "";
  return `/v1/customers/medical_invoice/${id}/download${queryParams}`;
}

//stres
export const API_STRESS_QUESTIONS = '/v2/customers/stress_questionnaires'
export const API_SUBMIT_STRESS_RESPONSE = '/v2/customers/stress_questionnaires'
export const API_GET_SUMMARY_DETAILS = '/v2/customers/stress_questionnaires/summary'

//Coupons
export const API_VALIDATE_COUPON = '/v2/coupons/validate'
export const API_COUPON_OFFER = (id) => `/v2/customers/coupon_offers?service_type=${id}`

// Gym APIs
export const API_FITNESS_SLOTS = '/v2/customers/fitness/slots';
export const API_FITNESS_PROVIDERS = '/v2/customers/fitness/providers';
export const API_FITNESS_APPOINTMENTS = '/v2/customers/fitness/appointments';
export const API_FITNESS_APPOINTMENTS_CANCEL = (id) => `/v2/customers/fitness/appointments/${id}/cancel`
export const API_GYMS_SLOTS = (latitude, longitude, date) => `/v2/customers/fitness/slots?latitude=${latitude}&longitude=${longitude}&date=${date}`
export const API_GYMS_LOCATION = (latitude, longitude, category, start_time, date) => `/v2/customers/fitness/locations?latitude=${latitude}&longitude=${longitude}&category=${category}&start_time=${start_time}&date=${date}`
export const API_GYMS_PAYMENT_DETAILS = `/v3/customers/fitness/slot_details`
export const API_GYMS_SUBSCRIPTION = `/v2/customers/fitness/subscriptions/available`
export const API_GYMS_BOOKING = `/v2/customers/fitness/subscriptions`
export const API_GYM_POST_PAYMENT = `/v2/customers/fitness/subscriptions/post_payment`
export const API_GYM_UNLOCK_SESSION = (id) => `/v2/customers/fitness/appointments/${id}/unlock_session`

//Communications

export const API_ENABLE_WHATSAPP = '/v2/customers/whatsapp_communications/enable_or_disable'

//ewap phase-II
export const API_GET_APPOINTMENT_DETAILS = (id) => `/v3/customers/benefits/appointments/${id}`
export const API_COMPLETE_EWAP_APPT = (id) => `/v2/customers/ewap/ewap_appointment_requests/${id}/complete`
export const API_FETCH_EWAP_TOKEN = '/v2/customers/ewap/encrypted-token';

//Specialist consultation
export const API_FETCH_SPECIALIST_PROVIDERS = (benefitType = 'specialist_consultation') => `/v3/customers/benefits/${benefitType}/providers`
export const API_SPENDING_ACCOUNT = (benefitType, planId) => `/v3/customers/benefits/${benefitType}/know_more?plan_id=${planId ?? ''}`
// export const API_FETCH_SLOTS = (benefitType = 'specialist_consultation') => `/v3/customers/benefits/${benefitType}/slots`
export const API_FETCH_REPORTS_UPLOAD_OPTIONS = (aptId) => `/v2/customers/documents/consultation_upload_options?appointment_id=${aptId}`
export const API_DELETE_REPORTS = (type, id, aptId) => `/v2/customers/documents/destroy_consultation_upload?type=${type}&id=${id}&appointment_id=${aptId}`
export const API_UPLOAD_REPORTS = (aptId) => `/v2/customers/appointments/upload_documents/${aptId}`
export const API_FETCH_UPLOADED_RECORDS = (aptId) => `/v2/customers/appointments/uploaded_documents/${aptId}`

//benefits redesign
export const API_FETCH_BENEFITS = (planId) => `/v2/customers/benefits?plan_id=${planId ?? ''}`
export const API_BENEFIT_DETAILS = (type) => `/v2/customers/benefits/${type}`
export const API_BENEFIT_DETAILS_NEW = (type) => `/v3/customers/benefits/${type}`
export const API_BENEFIT_TRANSACTIONS = (id) => `/v3/customers/benefits/${id}/transactions`
export const API_BENEFIT_PROGRAM_DETAILS = (id) => `/v2/customers/health_coach/programs/${id}`
export const API_FETCH_HEALTH_CHECK_BENEFITS = '/v2/customers/benefits/health_checks'
export const API_FETCH_AHC_FAMILY_MEMBERS = (type, planId) => `/v3/customers/benefits/${type}/members?plan_id=${planId ?? ''}`
export const API_HC_CART = (type, planId) => `/v3/customers/benefits/${type}/cart?plan_id=${planId ?? ''}`
export const API_FETCH_AHC_PACKAGES = (type, customerId, planId) => `/v3/customers/benefits/${type}/packages?customer_id=${customerId}&plan_id=${planId ?? ''}`
export const API_BENEFITS_MENTAL_WELLNESS = '/v2/customers/benefits/mental_wellness'
export const API_BENEFIT_REIMBURSEMENT = (form_type, planId) => `/v3/customers/benefits/benefit_forms?form_type=${form_type}${planId ? `&plan_id=${planId}` : ''}`;
export const API_AMBULANCE_ON_DEMAND = `/v2/customers/benefits/ambulance_on_demand`

//plans 
export const API_FETCH_PLANS = 'v3/customers/plans'
export const FETCH_IPD_INTEGRATION_DETAILS = 'v2/customers/ipd_integration_details'

//1mg pincode
export const API_VALIDATE_PINCODE = `/v2/customers/epharmacy/serviceable`
export const API_HEALTH_CHECKS_PAYMENT_OPTIONS = '/v3/customers/benefits/appointments/payment_options'


//insurance-registration and login
export const API_VALIDATE_POLICY_NUMBER = '/v2/customers/policy_user_details/validate_policy_number'
export const VERIFY_POLICY = '/v2/customers/policy_user_details/validate'
export const SEND_OTP = '/v2/customers/policy_user_details/otp'
export const VALIDATE_OTP = '/v2/customers/policy_user_details/validate_otp'

// group health insurance API
export const API_GROUP_HEALTH_INSURANCE = 'v3/customers/group_health_insurance'

//rewards
export const API_GET_REWARDS_DETAILS = '/v2/customers/rewards'
export const API_GET_REWARDS_TRANSACTIONS = '/v2/customers/reward_transactions'
export const API_UPLOAD_VERIFIABLE_DOCUMENT = '/v2/customers/verifiable_documents'
export const API_VIEW_VERIFIABLE_DOCUMENTS = '/v2/customers/verifiable_documents'
export const API_DOWNLOAD_VERIFIABLE_DOCUMENT = (id) => `/v2/customers/verifiable_documents/${id}/download`
export const API_REWARDS_REDEEM = '/v2/customers/rewards/redeem'

//identity details
export const API_STORE_IDENTITY = '/v2/customers/update_identity_card'
export const API_UPDATE_COVIN_REG_STATUS = '/v2/customers/update_cowin_registration_status'

//provider location request
export const API_NEW_PROVIDER_REQUEST = '/v2/customers/new_provider_request'
//Pharmacy new apis
export const AUTOCOMPLETE_SEARCH = '/v2/customers/epharmacy/search/autocomplete'
export const SEARCH_MEDICINE = '/v2/customers/epharmacy/search'
export const FETCH_PRODUCT_DETAILS = (id) => `/v2/customers/epharmacy/products/${id}`
export const FETCH_BY_CATEGORY = (id) => `/v2/customers/epharmacy/product_categories/${id}`
export const API_FETCH_PHARMACY_ORDER_CANCEL = (id) => `/v2/customers/epharmacy/cart_orders/${id}/cancel`
export const API_PHARMACY_TRANSACTIONS = '/v2/customers/epharmacy_orders/transactions'
export const API_DEACTIVATE_FAMILY_MEMBER = (token) => `/v2/customers/family_members/${token}/deactivate`
export const API_PHARMACY_CATEGORIES = '/v2/customers/epharmacy/product_pharmacy_categories'
export const API_MARKETING_TILES = '/v2/customers/marketing_tiles'
export const UPDATE_CART = (planId) =>  `/v2/customers/epharmacy/update-cart?plan_id=${planId ?? ''}`
export const FETCH_CART_DETAILS = (planId) => `/v2/customers/epharmacy/cart?plan_id=${planId ?? ''}`

//Dynamic Form  apis
export const API_GET_DYNAMIC_FORM_QUESTIONS = (id, benefitId, reimbursementId) => {
  let url = `/v2/customers/dynamic_forms/${id}`;
  if (benefitId) url += `?benefit_id=${benefitId}`;
  if (reimbursementId) url += `${benefitId ? '&' : '?'}reimbursement_id=${reimbursementId}`;
  return url;
};

export const API_UPLOAD_FORM_RESPONSE = `/v2/customers/form_response_file/upload`
export const API_DELETE_UPLOAD_FORM_RESPONSE = `/v2/customers/form_response_file/delete_all`
export const API_POST_DYNAMIC_FORM_QUESTIONS_RESPONSE = (form_submission_id, id) => `/v2/customers/customer_form_submissions/${form_submission_id}/form_questions/${id}`
export const API_FORM_POST_PAYMENT_RESPONSE = (form_submission_id) => `/v2/customers/customer_form_submissions/${form_submission_id}/post_payment`

export const API_GET_PRESCRIPTION_DETAILS = `/v3/customers/benefits/appointments/rx_required_appointment`;

// Reimbursement API's
export const API_GET_REIMBURSEMENT_CLAIM_LIST = (tab) => `/v2/customers/reimbursement?tab=${tab}`;
export const API_GET_REIMBURSEMENT_DETAILS = (id) => `/v2/customers/reimbursement/${id}`;
export const API_BANK_ACCOUNT_DETAILS = `/v2/customers/bank_account`;
export const API_GET_CUSTOMER_BENEFIT_SUBLIMIT = (benefitId, reimbursementId, formId) => {
  let url = `/v2/customers/reimbursement/benefit_limit?benefit_id=${benefitId}&customer_form_submission_id=${formId}`
  if (reimbursementId) url += `&reimbursement_id=${reimbursementId}`;
  return url;
}
export const API_RESPOND_DEFICIENCY = (id) => `/v2/customers/reimbursement/${id}/respond_deficiency`;
export const API_GET_QUESTION_RESPONSE = (reimbursementId, questionId) => `/v2/customers/reimbursement/question_response?reimbursement_id=${reimbursementId}&question_id=${questionId}`
export const API_GET_PRESCRIPTION_RAZORPAY_DETAILS = (id) => `/v3/customers/benefits/appointments/${id}/generate_rx_payment_link`;

// Value Plus API's
export const API_GET_AVAILABLE_VALUE_PLUS_SUBSCRIPTIONS = '/v2/customers/value_plus_subscriptions/available';
export const API_GET_PAYMENT_DETAILS_VALUE_PLUS_SUBSCRIPTIONS = (id) => `/v2/customers/value_plus_subscriptions/payment_details?subscription_id=${id}`;
export const API_CREATE_VALUE_PLUS_SUBSCRIPTIONS = '/v2/customers/value_plus_subscriptions';
export const API_PATCH_CONFIRM_VALUE_PLUS_SUBSCRIPTION = (id) => `/v2/customers/value_plus_subscriptions/${id}/confirm`;
export const API_GET_VALUE_PLUS_SUBSCRIPTION_STATUS = (id) => `/v2/customers/value_plus_subscriptions/${id}/status`;

// booking API's
export const API_GET_FETCH_FINAL_CART_SUMMARY_WITH_VALUE_PLUS = '/v2/customers/bookings/payment_details'
export const API_POST_CREATE_BOOKING_WITH_VALUE_PLUS = '/v2/customers/bookings'
export const API_PATCH_CONFIRM_BOOKING_WITH_VALUE_PLUS = (id) => `/v2/customers/bookings/${id}/confirm`

//global cart
export const API_GET_GLOBAL_CART = (planId) => `/v3/customers/benefits/global_cart?plan_id=${planId ?? ''}`

//milestone challenge
export const API_MILESTONE_CHALLENGE = '/v2/customers/monthly_milestone_challenge'